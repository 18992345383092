import { getCookie } from 'cookies-next';
import React, { useState } from 'react';

import { useGetInvitationById } from '@/shared/firebase/client/hooks/invitation.hooks';

function InvitedWorkspace() {
  const [invitationId] = useState<string | undefined>(() =>
    getCookie('invitationId')?.toString()
  );

  const { data: invitation, state: invitationState } =
    useGetInvitationById(invitationId);

  if (!invitation || invitationState.isLoading) return null;

  return (
    <div className="flex items-center justify-start gap-3">
      {invitationState.isSuccess && invitation && (
        <div className="rounded-xl border border-dark-200 p-4 dark:border-dark-600">
          You are about to join{' '}
          <span className="font-bold text-success">
            {invitation.workspace.name}{' '}
          </span>
          workspace
        </div>
      )}
    </div>
  );
}

export default InvitedWorkspace;
